import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  Input,
  InputNumber,
  Button,
  Checkbox,
  Select
} from 'antd';
import styled from 'styled-components';
import FormInput from './components/FormInput';
import FormInputCount from './components/FormInputCount';
import usePostData from '../../shared/hooks/usePostData';

export default () => {
  const [eventCode, setEventCode] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [email, setEmail] = useState('');
  const [site, setSite] = useState('');
  const [typeOfCleanup, setTypeOfCleanup] = useState('');
  const [trashCollected, setTrashCollected] = useState([
    { group: 1, type: 1, count: 0 },
    { group: 1, type: 2, count: 0 },
    { group: 1, type: 3, count: 0 },
    { group: 1, type: 4, count: 0 },
    { group: 1, type: 5, count: 0 },
    { group: 1, type: 6, count: 0 },
    { group: 1, type: 7, count: 0 },
    { group: 1, type: 8, count: 0 },
    { group: 1, type: 9, count: 0 },
    { group: 1, type: 10, count: 0 },
    { group: 1, type: 11, count: 0 },
    { group: 1, type: 12, count: 0 },
    { group: 1, type: 13, count: 0 },
    { group: 1, type: 14, count: 0 },
    { group: 1, type: 15, count: 0 },
    { group: 1, type: 16, count: 0 },
    { group: 1, type: 17, count: 0 },
    { group: 1, type: 18, count: 0 },
    { group: 2, type: 19, count: 0 },
    { group: 2, type: 20, count: 0 },
    { group: 2, type: 21, count: 0 },
    { group: 2, type: 22, count: 0 },
    { group: 3, type: 23, count: 0 },
    { group: 3, type: 24, count: 0 },
    { group: 3, type: 25, count: 0 },
    { group: 3, type: 26, count: 0 },
    { group: 3, type: 27, count: 0 },
    { group: 4, type: 28, count: 0 },
    { group: 4, type: 29, count: 0 },
    { group: 4, type: 30, count: 0 },
    { group: 4, type: 31, count: 0 },
    { group: 4, type: 32, count: 0 },
    { group: 4, type: 33, count: 0 },
    { group: 4, type: 34, count: 0 },
    { group: 5, type: 35, count: 0 },
    { group: 5, type: 36, count: 0 },
    { group: 5, type: 37, count: 0 },
    { group: 5, type: 38, count: 0 },
    { group: 7, type: 39, count: 0 },
    { group: 7, type: 40, count: 0 },
    { group: 7, type: 41, count: 0 }
  ]);
  const [itemsOfLocalConcern, setItemsOfLocalConcern] = useState([]);
  const [animalsCollected, setAnimalsCollected] = useState([]);

  const [loading, submit, submitSuccessful] = usePostData({
    endPoint: `/collectors`,
    successMessage: null
  });

  const [verifyModalOpen, setVerifyModalOpen] = useState(false);

  const reloadAll = () => {
    setEventCode('');
    setSite('');
    setTypeOfCleanup('');
    setName('');
    setAddress('');
    setContactNumber('');
    setEmail('');
    setTrashCollected([
      { group: 1, type: 1, count: 0 },
      { group: 1, type: 2, count: 0 },
      { group: 1, type: 3, count: 0 },
      { group: 1, type: 4, count: 0 },
      { group: 1, type: 5, count: 0 },
      { group: 1, type: 6, count: 0 },
      { group: 1, type: 7, count: 0 },
      { group: 1, type: 8, count: 0 },
      { group: 1, type: 9, count: 0 },
      { group: 1, type: 10, count: 0 },
      { group: 1, type: 11, count: 0 },
      { group: 1, type: 12, count: 0 },
      { group: 1, type: 13, count: 0 },
      { group: 1, type: 14, count: 0 },
      { group: 1, type: 15, count: 0 },
      { group: 1, type: 16, count: 0 },
      { group: 1, type: 17, count: 0 },
      { group: 1, type: 18, count: 0 },
      { group: 2, type: 19, count: 0 },
      { group: 2, type: 20, count: 0 },
      { group: 2, type: 21, count: 0 },
      { group: 2, type: 22, count: 0 },
      { group: 3, type: 23, count: 0 },
      { group: 3, type: 24, count: 0 },
      { group: 3, type: 25, count: 0 },
      { group: 3, type: 26, count: 0 },
      { group: 3, type: 27, count: 0 },
      { group: 4, type: 28, count: 0 },
      { group: 4, type: 29, count: 0 },
      { group: 4, type: 30, count: 0 },
      { group: 4, type: 31, count: 0 },
      { group: 4, type: 32, count: 0 },
      { group: 4, type: 33, count: 0 },
      { group: 4, type: 34, count: 0 },
      { group: 5, type: 35, count: 0 },
      { group: 5, type: 36, count: 0 },
      { group: 5, type: 37, count: 0 },
      { group: 5, type: 38, count: 0 },
      { group: 7, type: 39, count: 0 },
      { group: 7, type: 40, count: 0 },
      { group: 7, type: 41, count: 0 }
    ]);
    setItemsOfLocalConcern([]);
    setAnimalsCollected([]);
  }
  
  const doSubmit = () => {
    setVerifyModalOpen(false);
    if (loading) {
        return;
    }
    if (!name) {
        Modal.error({
          title: 'Uh Oh!',
          content: 'Name must be filled.',
        });
        return;
    }
    if (!email) {
        Modal.error({
          title: 'Uh Oh!',
          content: 'Email must be filled.',
        });
        return;
    }
    if (!eventCode) {
        Modal.error({
          title: 'Uh Oh!',
          content: 'Event code must be filled.',
        });
        return;
    }
    if (!address) {
        Modal.error({
          title: 'Uh Oh!',
          content: 'Address must be filled.',
        });
        return;
    }
    if (!contactNumber) {
        Modal.error({
          title: 'Uh Oh!',
          content: 'Contact number must be filled.',
        });
        return;
    }
    if (!site) {
      Modal.error({
        title: 'Uh Oh!',
        content: 'Cleanup Site must be filled.',
      });
      return;
  }
  if (!typeOfCleanup) {
      Modal.error({
        title: 'Uh Oh!',
        content: 'Type of Cleanup must be filled.',
      });
      return;
  }

    const items_of_local_concern_incomplete = itemsOfLocalConcern.some(item => !item.type_string);
    if (items_of_local_concern_incomplete) {
        Modal.error({
          title: 'Uh Oh!',
          content: 'All items of local concern must have a name.',
        });
        return;
    }

    const trash_collected = [
        ...trashCollected.map(trash => {
            return {
                ...trash,
                count: trash.count || 0
            };
        }),
        ...itemsOfLocalConcern.map(item => {
            return {
                ...item,
                count: item.count || 0
            };
        })
    ];

    submit({
      name: name,
      event_code: eventCode.toUpperCase(),
      address: address,
      contact_number: contactNumber,
      email: email,
      trash_collected,
      animals_collected: animalsCollected,
      site: site,
      type_of_cleanup: typeOfCleanup
    });
  }

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (loading) {
      isFirstRun.current = false;
      return;
    } else if (isFirstRun.current) {
      return;
    }

    if (submitSuccessful) {
      Modal.success({
        title: 'Yay!',
        content: 'Thank you for helping keep our shores clean.',
      });
      reloadAll();
    } else {
      Modal.error({
        title: 'Uh Oh!',
        content: 'Something went wrong. Please try again.',
      });
    }
  }, [loading, submitSuccessful]);

  return (
    <FormParent>
      <Header>
          <div style={{ fontWeight: 'bold', fontSize: 24, color: '#FFFFFF' }}>Let's Shore It Up Mabini!</div>
          <div style={{ fontWeight: 'bold', fontSize: 18, color: '#FFFFFF' }}>Trash Collection Form</div>
      </Header>
      <FormContainer>
        <FormCard>
          <FormInput label="Event Code">
            <AllCapsInput 
              value={eventCode} 
              onChange={e => setEventCode(e.target.value)} 
              placeholder='ICC2022'
            />
          </FormInput>
          <FormInput label="Name">
            <Input 
              value={name} 
              onChange={e => setName(e.target.value)} 
              placeholder='Juan Dela Cruz'
            />
          </FormInput>
          <FormInput label="Address">
            <Input 
              value={address} 
              onChange={e => setAddress(e.target.value)} 
              placeholder='Street Number, Street Name, City'
            />
          </FormInput>
          <FormInput label="Contact Number">
            <Input 
              value={contactNumber} 
              onChange={e => setContactNumber(e.target.value)} 
              placeholder='09181111111'
            />
          </FormInput>
          <FormInput label="Email">
            <Input 
              value={email} 
              onChange={e => setEmail(e.target.value)} 
              placeholder='volunteer@email.com'
            />
          </FormInput>
          <FormInput label="Clean-Up Site">
            <Input 
              value={site} 
              onChange={e => setSite(e.target.value)} 
            />
          </FormInput>
          <FormInput label="Tyoe of Clean-Up">
            <Select
              value={typeOfCleanup}
              onChange={value => setTypeOfCleanup(value)}
              style={{ width: '300px' }}
            >
              <Select.Option
                key='coastal'
                value='coastal'
              >
                Coastal
              </Select.Option>
              <Select.Option
                key='underwater'
                value='underwater'
              >
                Underwater
              </Select.Option>
            </Select>
          </FormInput>
          <ListHeader>
            <ListHeaderTitle>TRASH COLLECTED</ListHeaderTitle>
          </ListHeader>
          <ListHeader>
            <ListHeaderSubtitle>MOST LIKELY TO FIND ITEMS:</ListHeaderSubtitle>
          </ListHeader>
          <ListContainer>
            <FormInputCount label="Cigarette Butts">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[0].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[0].count}
                />
            </FormInputCount>
            <FormInputCount label="Food Wrappers (candy, chips, etc)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[1].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[1].count}
                />
            </FormInputCount>
            <FormInputCount label="Take Out/Away Containers (Plastic)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[2].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[2].count}
                />
            </FormInputCount>
            <FormInputCount label="Take Out/Away Containers (Foam)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[3].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[3].count}
                />
            </FormInputCount>
            <FormInputCount label="Bottle Caps (Plastic)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[4].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[4].count}
                />
            </FormInputCount>
            <FormInputCount label="Bottle Caps (Metal)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[5].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[5].count}
                />
            </FormInputCount>
            <FormInputCount label="Lids (Plastic)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[6].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[6].count}
                />
            </FormInputCount>
            <FormInputCount label="Straws / Stirrers">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[7].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[7].count}
                />
            </FormInputCount>
            <FormInputCount label="Forks, Knives, Spoons">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[8].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[8].count}
                />
            </FormInputCount>
            <FormInputCount label="Bevarage Bottles (Plastic)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[9].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[9].count}
                />
            </FormInputCount>
            <FormInputCount label="Bevarage Bottles (Glass)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[10].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[10].count}
                />
            </FormInputCount>
            <FormInputCount label="Bevarage Cans">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[11].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[11].count}
                />
            </FormInputCount>
            <FormInputCount label="Grocery Bags (Plastic)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[12].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[12].count}
                />
            </FormInputCount>
            <FormInputCount label="Other Plastic Bags">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[13].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[13].count}
                />
            </FormInputCount>
            <FormInputCount label="Paper Bags">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[14].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[14].count}
                />
            </FormInputCount>
            <FormInputCount label="Cups & Plates (Paper)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[15].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[15].count}
                />
            </FormInputCount>
            <FormInputCount label="Cups & Plates (Plastic)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[16].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[16].count}
                />
            </FormInputCount>
            <FormInputCount label="Cups & Plates (Foam)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[17].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[17].count}
                />
            </FormInputCount>
          </ListContainer>
          <ListHeader>
            <ListHeaderSubtitle>FISHING GEAR:</ListHeaderSubtitle>
          </ListHeader>
          <ListContainer>
            <FormInputCount label="Fishing Buoys, Pots & Traps">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[18].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[18].count}
                />
            </FormInputCount>
            <FormInputCount label="Fishing Net & Pieces">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[19].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[19].count}
                />
            </FormInputCount>
            <FormInputCount label="Fishing Line (1 yard/meter = 1 piece)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[20].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[20].count}
                />
            </FormInputCount>
            <FormInputCount label="Rope (1 yard/meter = 1 piece)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[21].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[21].count}
                />
            </FormInputCount>
          </ListContainer>
          <ListHeader>
            <ListHeaderSubtitle>PACKAGING MATERIALS:</ListHeaderSubtitle>
          </ListHeader>
          <ListContainer>
            <FormInputCount label="6-Pack Holders">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[22].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[22].count}
                />
            </FormInputCount>
            <FormInputCount label="Other Plastic/Foam Packaging">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[23].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[23].count}
                />
            </FormInputCount>
            <FormInputCount label="Other Plastic Bottles (oil, bleach, etc)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[24].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[24].count}
                />
            </FormInputCount>
            <FormInputCount label="Strapping Bands">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[25].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[25].count}
                />
            </FormInputCount>
            <FormInputCount label="Tobacco Packaging/Wrap">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[26].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[26].count}
                />
            </FormInputCount>
          </ListContainer>
          <ListHeader>
            <ListHeaderSubtitle>OTHER TRASH:</ListHeaderSubtitle>
          </ListHeader>
          <ListContainer>
            <FormInputCount label="Appliances (refrigerators, washers, etc)">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[27].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[27].count}
                />
            </FormInputCount>
            <FormInputCount label="Balloons">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[28].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[28].count}
                />
            </FormInputCount>
            <FormInputCount label="Cigar Tips">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[29].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[29].count}
                />
            </FormInputCount>
            <FormInputCount label="Cigarette Lighters">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[30].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[30].count}
                />
            </FormInputCount>
            <FormInputCount label="Construction Materials">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[31].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[31].count}
                />
            </FormInputCount>
            <FormInputCount label="Fireworks">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[32].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[32].count}
                />
            </FormInputCount>
            <FormInputCount label="Tires">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[33].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[33].count}
                />
            </FormInputCount>
          </ListContainer>
          <ListHeader>
            <ListHeaderSubtitle>PERSONAL HYGIENE:</ListHeaderSubtitle>
          </ListHeader>
          <ListContainer>
            <FormInputCount label="Condoms">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[34].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[34].count}
                />
            </FormInputCount>
            <FormInputCount label="Diapers">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[35].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[35].count}
                />
            </FormInputCount>
            <FormInputCount label="Syringes">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[36].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[36].count}
                />
            </FormInputCount>
            <FormInputCount label="Tampons/Tampon Applicators">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[37].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[37].count}
                />
            </FormInputCount>
            </ListContainer>
          <ListHeader>
            <ListHeaderSubtitle>TINY TRASH LESS THAN 2.5CM:</ListHeaderSubtitle>
          </ListHeader>
          <ListContainer>
            <FormInputCount label="Foam Pieces">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[38].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[38].count}
                />
            </FormInputCount>
            <FormInputCount label="Glass Pieces">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[39].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[39].count}
                />
            </FormInputCount>
            <FormInputCount label="Plastic Pieces">
                <InputNumber
                  style={{ width: "100%" }}
                  onFocus={e => e.target.select()}
                  min={0}
                    onChange={(text) => {
                        const input = text;
                        const trash_collected = [...trashCollected];
                        trash_collected[40].count = input;
                        setTrashCollected(trash_collected);
                    }}
                    value={trashCollected[40].count}
                />
            </FormInputCount>
          </ListContainer>
          <ListHeaderWithButton>
          <ListHeaderSubtitle>ITEMS OF LOCAL CONCERN:</ListHeaderSubtitle>
            <Button 
              onClick={() => {
                setItemsOfLocalConcern([...itemsOfLocalConcern, { group: 6, type_string: '', count: 0}])
              }}
              type='primary'
            >
              ADD (+)
            </Button>
          </ListHeaderWithButton>
          <ListContainer>
          {
            itemsOfLocalConcern.map((item, key, items) => {
              return (
                <ItemOfLocalConcernContainer key={key}>
                  <ItemOfLocalContainerInput
                    placeholder='Item Type'
                    onChange={(e) => {
                        const items_of_local_concern = [...items];
                        items_of_local_concern[key].type_string = e.target.value;
                        setItemsOfLocalConcern(items_of_local_concern);
                    }}
                    value={item.type_string}
                  />
                  <InputNumber
                    style={{ flex: 1 }}
                    onFocus={e => e.target.select()}
                    min={0}
                    onChange={(text) => {
                        const input = text;
                        const items_of_local_concern = [...items];
                        items_of_local_concern[key].count = input;
                        setItemsOfLocalConcern(items_of_local_concern);
                    }}
                    value={item.count}
                  />
                </ItemOfLocalConcernContainer>
              );
            })
          } 
          </ListContainer>
          <ListHeaderWithButton>
          <ListHeaderSubtitle>DEAD/INJURED ANIMAL:</ListHeaderSubtitle>
            <Button 
              onClick={() => {
                setAnimalsCollected([...animalsCollected, { type: '', dead: false, entangled: false, item: '' }])
              }}
              type='primary'
            >
              ADD (+)
            </Button>
          </ListHeaderWithButton>
          <AnimalListContainer>
          {
            animalsCollected.map((item, key, items) => {
                return (
                  <AnimalCollectedContainer key={key}>
                    <AnimalCollectedLineOne>
                      <ItemOfLocalContainerInput
                        placeholder='Dead/Injured Animal'
                        onChange={(e) => {
                            const animals_collected = [...items];
                            animals_collected[key].type = e.target.value;
                            setAnimalsCollected(animals_collected);
                        }}
                        value={item.type}
                      />
                      <Checkbox
                        onChange={(e) => {
                            const animals_collected = [...items];
                            animals_collected[key].dead = e.target.checked;
                            setAnimalsCollected(animals_collected);
                        }}
                        checked={item.dead}
                      >
                        Is it dead?  
                      </Checkbox>
                    </AnimalCollectedLineOne>
                    <AnimalCollectedLineTwo>
                      <Checkbox
                        onChange={(e) => {
                            const animals_collected = [...items];
                            animals_collected[key].entangled = e.target.checked;
                            setAnimalsCollected(animals_collected);
                        }}
                        checked={item.entangled}
                      >
                        Is it entangled?  
                      </Checkbox>
                      <ItemOfLocalContainerInput
                        placeholder='Entanglement Item'
                        onChange={(e) => {
                            const animals_collected = [...items];
                            animals_collected[key].item = e.target.value;
                            setAnimalsCollected(animals_collected);
                        }}
                        value={item.item}
                      />
                    </AnimalCollectedLineTwo>
                  </AnimalCollectedContainer>
                );
            })
          }
          </AnimalListContainer>
          <ButtonContainer>
            <FormSubmitButton 
              loading={loading}
              onClick={() => setVerifyModalOpen(true)}
              type='primary'
            >
              SUBMIT
            </FormSubmitButton>
          </ButtonContainer>
        </FormCard>
      </FormContainer>
      <Modal
        open={verifyModalOpen}
        onOk={doSubmit}
        confirmLoading={loading}
        onCancel={() => setVerifyModalOpen(false)}
      >
        <div>Are you sure you want to submit?</div>
      </Modal>
    </FormParent>
  );
}

const FormParent = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#348ED0',
  display: 'flex',
  flexDirection: 'column'
});

const Header = styled.div(_ => ({
  height: '80px',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch', 
  alignItems: 'center', 
  justifyContent: 'center',
}));

const FormContainer = styled.div({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch',
  minHeight: 0
});

const FormCard = styled.div({
  flexGrow: 1,
  overflowY: 'auto', 
  minHeight: 0,
  backgroundColor: '#FFFFFFDD', 
  borderRadius: 10,
  margin: 10,
  alignSelf: 'stretch',
});

const AllCapsInput = styled(Input)({
  textTransform: 'uppercase'
});

const ListHeader = styled.div({
  padding: 10, 
  backgroundColor: '#44A4D0',
  marginBottom: '2px'
});

const ListHeaderTitle = styled.span({
  color: '#FFFFFF', 
  fontWeight: 'bold', 
  fontSize: 22
});

const ListHeaderSubtitle = styled.span({
  color: '#FFFFFF', 
  fontWeight: 'bold', 
  fontSize: 16
});

const ListContainer = styled.div({
  margin: '0 auto',
  display: 'grid',
  gap: '1rem',
  padding: '10px',
  '@media (min-width: 600px)': {
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  '@media (min-width: 900px)': {
    gridTemplateColumns: 'repeat(3, 1fr)'
  },
});

const ListHeaderWithButton = styled.div({
  padding: 10, 
  backgroundColor: '#44A4D0',
  marginBottom: '2px',
  display: 'flex',
  flexDirection: 'row', 
  alignItems: 'center', 
  justifyContent: 'space-between'
});

const ItemOfLocalConcernContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '5px',
  padding: '5px',
  background: '#FFFFFFAA',
});

const ItemOfLocalContainerInput = styled(Input)({
  margin: '5px',
  fontSize: '14px',
  flex: 3,
});

const AnimalListContainer = styled.div({
  margin: '0 auto',
  display: 'grid',
  gap: '1rem',
  padding: '10px',
  '@media (min-width: 900px)': {
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
});

const AnimalCollectedContainer = styled.div({
  padding: '10px',
  background: '#FFFFFFAA',
});

const AnimalCollectedLineOne = styled.div({
  display: 'grid',
  gap: '1rem',
  gridTemplateColumns: '3fr 1fr',
  alignItems: 'center'
});

const AnimalCollectedLineTwo = styled.div({
  display: 'grid',
  gap: '1rem',
  gridTemplateColumns: '1fr 3fr',
  alignItems: 'center'
});

const ButtonContainer = styled.div({
  padding: 10, 
  display: 'flex',
  justifyContent: 'center'
});

const FormSubmitButton = styled(Button)({
  width: '300px',
  borderRadius: 15,
});