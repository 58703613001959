import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Form from './routes/form/form';
import Results from './routes/results/results';

function App() {
  return (
    <Routes>
      <Route path="/results" element={<Results />} />
      <Route path="/" element={<Form />} />
    </Routes>
  );
}

export default App;
