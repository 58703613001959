import { useState, useEffect, useRef } from "react";
import { BASE_API_URL } from "../../shared/utils/constants";
import axios from "../../shared/utils/axios";
import { notification } from "antd";

export default ({
  baseUrl = BASE_API_URL,
  endPoint = "",
  params = {},
  errorMessage = {
    message: "Failed to retrieve data.",
    description: "Please refresh the page.",
    duration: 15
  },
  runOnInitiate = true
}) => {
  const isFirstRun = useRef(true);
  const config = { params, timeout: 30000, };
  // used for refetching
  const [queryParams, updateQueryParams] = useState(params);
  // useful for loading icons
  const [isFetching, updateIsFetching] = useState(false);
  /**
   * the actual data, default is undefined,
   * so a checker is needed when using the
   * returned data to evaluate it's existence
   */
  const [fetchedData, updateFetchedData] = useState(undefined);

  const [queryEndPoint, updateQueryEndPoint] = useState(endPoint);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      if (!runOnInitiate) {
        return;
      }
    }

    if (!queryEndPoint) {
      return;
    }

    updateIsFetching(true);
    axios
      .get(baseUrl + queryEndPoint, {
        ...config,
        params: { ...queryParams }
      })
      .then(response => updateFetchedData(response.data))
      .catch(error => {
        const response = error.response ? error.response : undefined;
        const status = response ? response.status : undefined;
        const data = response ? response.data : undefined;
        const message = data ? data.message : undefined;
        if (status !== undefined) {
          if (status === 403) {
            notification.error({
              ...errorMessage,
              message: 'Unauthorized',
              description: 'You are not allowed access. If you think this is a mistake, please contact HR / Admin.'
            });
          } else {
            notification.error({
              ...errorMessage,
              description: message ? message : errorMessage.message
            });
          }
        }
      })
      .finally(() => updateIsFetching(false));
  }, [queryParams, queryEndPoint]);

  /**
   * returning an array so that
   * end-user can use whatever
   * name for the values
   *
   * e.g.
   * const [loading, data] = useFetchData();
   */
  return [isFetching, fetchedData, updateQueryParams, updateQueryEndPoint];
};
