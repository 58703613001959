import React, { useState, useEffect } from 'react';
import { Select, Spin } from 'antd';
import useFetchData from '../../../../shared/hooks/useFetchData';

const Option = Select.Option;

export default ({
  value,
  onChange,
}) => {
  const [stateValue, setStateValue] = useState(value || undefined);
  const [fetching, response = { cleanup_types: [] }] = useFetchData({
    endPoint: '/collector/cleanup_types',
  });

  useEffect(() => {
    if (value === stateValue) {
      return;
    }
    setStateValue(value);
  }, [value]);

  const handleChange = (value) => {
    setStateValue(value);
    if (onChange) {
      onChange(value);
    }
  }

  return (
    <Select
      showSearch
      value={stateValue}
      placeholder={'Cleanup Type'}
      notFoundContent={fetching ? <Spin size="small" /> : 'Not Found'}
      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      onChange={handleChange}
      style={{ width: '300px' }}
    >
      <Option key={'all'} value={'all'}>All</Option>
      { response.cleanup_types.map(d => <Option key={d.type_of_cleanup} value={d.type_of_cleanup}>{d.type_of_cleanup.toUpperCase()}</Option>) }
    </Select>
  );
}