import React, { useState } from "react";
import styled from 'styled-components';

const FormInput = props => {
  return (
    <InputContainer>
      <Label>{props.label}:</Label>
      {props.children}
      <Error>{props.error}</Error>
    </InputContainer>
  );
};

export default FormInput;

const InputContainer = styled.div({
  margin: '10px'
});

const Label = styled.div({
  margin: '5px',
  fontWeight: 'bold',
  fontSize: '14px',
});

const Error = styled.div({

});
