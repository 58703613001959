import React from "react";
import styled from 'styled-components';

const FormInputCount = props => {
  return (
    <InputContainer>
      <Label>{props.label}:</Label>
      <Input>{props.children}</Input>
    </InputContainer>
  );
};

export default FormInputCount;

const InputContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  margin: '5px',
  padding: '5px',
  background: '#FFFFFFAA',
  alignItems: 'center'
});

const Label = styled.div({
  margin: '5px',
  fontWeight: 'bold',
  fontSize: '14px',
  flex: 3,
});

const Input = styled.div({
  flex: 1,
});
