import React, { useState, useEffect } from 'react';
import {
  Table
} from 'antd';
import styled from 'styled-components';
import useFetchData from '../../shared/hooks/useFetchData';
import CleanUpTypeSelect from './components/CleanUpTypeSelect';
import { keys, forEach, get } from 'lodash';

export default () => {
  const [cleanUpType, setCleanupType] = useState('all');
  const [fetchingTotals, totals = { groups: [], animals: 0 }, updateTotalsParams] = useFetchData({
    endPoint: '/collector/group_totals',
    runOnInitiate: false
  });
  const [
    fetchingCollectors, 
    collectors = { 
      data: [],  
      page: 1, 
      take: 20, 
      total: 0, 
      filters: [], 
      sort_key: 'id', 
      sort_dir: 'descend'
    }, 
    updateCollectorsParams
  ] = useFetchData({
    endPoint: '/collector/collector_group_totals',
    params: {
      page: 1, 
      take: 20, 
      total: 0, 
      filters: [], 
      sort_key: 'name', 
      sort_dir: 'ascend', 
      q: ''
    },
    runOnInitiate: false
  });

  const [
    fetchingItemTotals, 
    itemTotals = { 
      data: [],  
      page: 1, 
      take: 20, 
      total: 0, 
      filters: [], 
      sort_key: 'item_type', 
      sort_dir: 'ascend', 
    }, 
    updateItemTotalsParams
  ] = useFetchData({
    endPoint: '/collector/event_item_totals',
    params: {
      page: 1, 
      take: 20, 
      total: 0, 
      filters: [], 
      sort_key: 'item_type', 
      sort_dir: 'ascend', 
      q: ''
    },
    runOnInitiate: false
  });

  const [
    fetchingConcern, 
    itemConcerns = { 
      data: [],  
      page: 1, 
      take: 20, 
      total: 0, 
      filters: [], 
      sort_key: 'collected_item_name', 
      sort_dir: 'ascend'
    }, 
    updateItemConcernParams
  ] = useFetchData({
    endPoint: '/collector/items_of_local_concern',
    params: {
      page: 1, 
      take: 20, 
      total: 0, 
      filters: [], 
      sort_key: 'collected_item_name', 
      sort_dir: 'ascend'
    },
    runOnInitiate: false
  });

  const [
    fetchingAnimals, 
    itemAnimals = { 
      data: [],  
      page: 1, 
      take: 20, 
      total: 0, 
      filters: [], 
      sort_key: 'animal_type', 
      sort_dir: 'ascend'
    }, 
    updateAnimalsParams
  ] = useFetchData({
    endPoint: '/collector/animals',
    params: {
      page: 1, 
      take: 20, 
      total: 0, 
      filters: [], 
      sort_key: 'animal_type', 
      sort_dir: 'ascend'
    },
    runOnInitiate: false
  });

  useEffect(() => {
    if (!cleanUpType) {
      return;
    }
    
    updateTotalsParams({ cleanup_type: cleanUpType });
    updateCollectorsParams({ 
      cleanup_type: cleanUpType,
      page: 1, 
      take: 20, 
      total: 0, 
      filters: [], 
      sort_key: 'name', 
      sort_dir: 'ascend', 
      q: ''
    });
    updateItemTotalsParams({
      cleanup_type: cleanUpType,
      page: 1, 
      take: 20, 
      total: 0, 
      filters: [], 
      sort_key: 'item_type', 
      sort_dir: 'ascend', 
      q: ''
    });
    updateItemConcernParams({
      cleanup_type: cleanUpType,
      page: 1, 
      take: 20, 
      total: 0, 
      filters: [], 
      sort_key: 'collected_item_name', 
      sort_dir: 'ascend'
    });
    updateAnimalsParams({
      cleanup_type: cleanUpType,
      page: 1, 
      take: 20, 
      total: 0, 
      filters: [], 
      sort_key: 'animal_type', 
      sort_dir: 'ascend'
    });
  }, [cleanUpType]);

  let columns = []
  if (collectors.data.length > 0) {
    const cols = keys(collectors.data[0]);
    forEach(cols, col => {
      if (col !== 'id') {
        if (col === 'name') {
          columns.push({
            title: 'Name',
            dataIndex: 'name'
          });
        } else {
          columns.push({
            title: col,
            dataIndex: col
          });
        }
      }
    });
  }

  const onCollectorsTableChange = (pagination, _, __) => {
    updateCollectorsParams({
      cleanup_type: cleanUpType,
      take: pagination.pageSize,
      page: pagination.current,
      sort_key: 'name', 
      sort_dir: 'ascend', 
    });
  };

  const onItemTotalsTableChange = (pagination, _, __) => {
    updateItemTotalsParams({
      cleanup_type: cleanUpType,
      take: pagination.pageSize,
      page: pagination.current,
      sort_key: 'item_type', 
      sort_dir: 'ascend', 
    });
  };

  const onConcernsTableChange = (pagination, _, __) => {
    updateItemConcernParams({
      cleanup_type: cleanUpType,
      take: pagination.pageSize,
      page: pagination.current,
      sort_key: 'collected_item_nam', 
      sort_dir: 'ascend', 
    });
  };

  const onAnimalsTableChange = (pagination, _, __) => {
    updateAnimalsParams({
      cleanup_type: cleanUpType,
      take: pagination.pageSize,
      page: pagination.current,
      sort_key: 'animal_type', 
      sort_dir: 'ascend', 
    });
  };

  return (
    <Parent>
      <Header>
        <div style={{ fontWeight: 'bold', fontSize: 24, color: '#348ED0', }}>Let's Shore It Up Mabini!</div>
        <div style={{ fontWeight: 'bold', fontSize: 18, color: '#348ED0', }}>Trash Collection Results</div>
      </Header>
      <ListHeader>
        <ListHeaderTitle>Choose Type of Cleanup: </ListHeaderTitle>
        <CleanUpTypeSelect 
          onChange={cleanup_type => setCleanupType(cleanup_type)}
        />
      </ListHeader>
      <TotalParent>
      {
        totals.groups.map(group => (
          <TotalCard key={group}>
            <TotalTitle>{group.group}</TotalTitle>
            <TotalCount>{group.total}</TotalCount>
          </TotalCard>
        ))
      }
      {
        !!cleanUpType && (
          <TotalCard>
            <TotalTitle>Animals Collected</TotalTitle>
            <TotalCount>{totals.animals}</TotalCount>
          </TotalCard>
        )
      }
      </TotalParent>
      <ListHeader>
        <ListHeaderTitle>Total Items Per Group:</ListHeaderTitle>
      </ListHeader>
      <Table 
        dataSource={collectors.data} 
        columns={columns} 
        rowKey='id' 
        onChange={onCollectorsTableChange}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['20', '50', '100'],
          total: get(collectors, 'total') || 0,
          current: get(collectors, 'page') || 1,
          pageSize: get(collectors, 'take') || 10
        }}
      />
      <ListHeader>
        <ListHeaderTitle>Total Items Per Type:</ListHeaderTitle>
      </ListHeader>
      <Table 
        dataSource={itemTotals.data} 
        columns={[
          {
            title: 'Item Type',
            dataIndex: 'item_type'
          },
          {
            title: '# of Collected',
            dataIndex: 'total'
          },
        ]} 
        rowKey='id' 
        onChange={onItemTotalsTableChange}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['20', '50', '100'],
          total: get(itemTotals, 'total') || 0,
          current: get(itemTotals, 'page') || 1,
          pageSize: get(itemTotals, 'take') || 10
        }}
      />
      <ListHeader>
        <ListHeaderTitle>Total Items Of Concern:</ListHeaderTitle>
      </ListHeader>
      <Table 
        dataSource={itemConcerns.data} 
        columns={[
          {
            title: 'Collected by ',
            dataIndex: 'name'
          },
          {
            title: 'Item Type',
            dataIndex: 'collected_item_name'
          },
          {
            title: '# of Collected',
            dataIndex: 'count'
          },
        ]} 
        rowKey='id' 
        onChange={onConcernsTableChange}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['20', '50', '100'],
          total: get(itemConcerns, 'total') || 0,
          current: get(itemConcerns, 'page') || 1,
          pageSize: get(itemConcerns, 'take') || 10
        }}
      />
      <ListHeader>
        <ListHeaderTitle>Total Animals:</ListHeaderTitle>
      </ListHeader>
      <Table 
        dataSource={itemAnimals.data} 
        columns={[
          {
            title: 'Collected by ',
            dataIndex: 'name'
          },
          {
            title: 'Animal Type',
            dataIndex: 'animal_type'
          },
          {
            title: 'Dead',
            dataIndex: 'dead',
            render: (val) => val ? 'Yes' : 'No',
          },
          {
            title: 'Entangled',
            dataIndex: 'entangled',
            render: (val) => val ? 'Yes' : 'No',
          },
          {
            title: 'Type of Entanglement Item',
            dataIndex: 'type_of_item'
          },
        ]} 
        rowKey='id' 
        onChange={onAnimalsTableChange}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['20', '50', '100'],
          total: get(itemAnimals, 'total') || 0,
          current: get(itemAnimals, 'page') || 1,
          pageSize: get(itemAnimals, 'take') || 10
        }}
      />
    </Parent>
  );
}

const Parent = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column'
});

const Header = styled.div(_ => ({
  height: '80px',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch', 
  alignItems: 'center', 
  justifyContent: 'center',
}));

const ListHeader = styled.div({
  padding: 10, 
  backgroundColor: '#44A4D0',
  marginBottom: '2px'
});

const ListHeaderTitle = styled.span({
  color: '#FFFFFF', 
  fontWeight: 'bold', 
  fontSize: 22
});

const TotalParent = styled.div({
  margin: '0 auto',
  display: 'grid',
  gap: '1rem',
  padding: '10px',
  gridTemplateColumns: 'repeat(2, 1fr)',
  '@media (min-width: 600px)': {
    gridTemplateColumns: 'repeat(3, 1fr)'
  },
  '@media (min-width: 900px)': {
    gridTemplateColumns: 'repeat(5, 1fr)'
  },
  backgroundColor: '#FFFFFFAA',
  padding: '10px'
});

const TotalCard = styled.div({
  borderRadius: '10px',
  backgroundColor: '#EFEFEF',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

const TotalTitle = styled.div({
  color: '#44A4D0',
  fontWeight: 'bold',
  fontSize: '16px'
});

const TotalCount = styled.div({
  color: '#348ED0',
  fontWeight: 'bold',
  fontSize: '32px'
});