import axios from "axios";
import { BASE_API_URL, TIMEOUT } from "./constants";
import { get } from 'lodash';

const instance = axios.create({
  baseURL: BASE_API_URL,
  timeout: TIMEOUT,
});

export default instance;
