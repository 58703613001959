import { useState } from "react";
import { BASE_API_URL } from "../../shared/utils/constants";
import axios from "../../shared/utils/axios";
import { notification } from "antd";

export default ({
  baseUrl = BASE_API_URL,
  endPoint = "",
  config = {
    timeout: 30000,
  },
  errorMessage = {
    message: "Failed to save data.",
    description: "Please refresh the page.",
    duration: 5,
  },
  successMessage = {
    message: "Data saved successfully.",
    duration: 5,
  },
}) => {
  // useful for loading icons
  const [isPosting, updateIsPosting] = useState(false);
  // set to false is default
  const [isSuccessful, updateIsSuccessful] = useState(false);
  /**
   * the actual data, default is undefined,
   * so a checker is needed when using the
   * returned data to evaluate it's existence
   */
  const [responseData, updateResponseData] = useState(undefined);

  const [queryEndpoint, setQueryEndpoint] = useState(endPoint);

  const rePost = (queryParams) => {
    updateIsPosting(true);
    // always reset before the post request
    updateIsSuccessful(false);
    axios
      .post(baseUrl + queryEndpoint, queryParams, config)
      .then((response) => {
        updateIsSuccessful(true);
        updateResponseData(response.data);
        if (successMessage) {
          notification.success(successMessage);
        }
      })
      .catch((error) => {
        const response = error.response ? error.response : undefined;
        const status = response ? response.status : undefined;
        const data = response ? response.data : undefined;
        const message = data ? data.message : undefined;
        if (status !== undefined) {
          if (status === 403) {
            notification.error({
              ...errorMessage,
              message: 'Unauthorized',
              description: 'You are not allowed access. If you think this is a mistake, please contact HR / Admin.'
            });
          } else {
            notification.error({
              ...errorMessage,
              description: message ? message : errorMessage.message
            });
          }
        }
      })
      .finally(() => updateIsPosting(false));
  };

  /**
   * returning an array so that
   * end-user can use whatever
   * name for the values
   *
   * e.g.
   * const [loading, data] = useFetchData();
   */
  return [isPosting, rePost, isSuccessful, responseData, setQueryEndpoint];
};
